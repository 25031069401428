/* Global Styles */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&family=Share+Tech+Mono&display=swap');

:root {
  --primary-color: #050505;
  --secondary-color: #0f1923;
  --accent-color: #00c2ff;
  --accent-color-alt: #ff003c;
  --text-color: #e0e0e0;
  --text-secondary: #a0a0a0;
  --border-color: rgba(0, 194, 255, 0.2);
  --gradient-dark: linear-gradient(135deg, #050505 0%, #0f1923 100%);
  --gradient-accent: linear-gradient(135deg, #00c2ff 0%, #0070ff 100%);
  --font-primary: 'Rajdhani', sans-serif;
  --font-secondary: 'Roboto', sans-serif;
  --font-mono: 'Share Tech Mono', monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--primary-color);
  color: var(--text-color);
  font-family: var(--font-secondary);
  line-height: 1.6;
  overflow-x: hidden;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Digital Noise Effect */
.digital-noise {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  opacity: 0.03;
  pointer-events: none;
  z-index: 1;
}

/* Grid Overlay */
.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, var(--border-color) 1px, transparent 1px),
    linear-gradient(to bottom, var(--border-color) 1px, transparent 1px);
  background-size: 40px 40px;
  opacity: 0.1;
  pointer-events: none;
  z-index: 1;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.8s ease-out, visibility 0.8s ease-out;
}

.loading-overlay.fade-out {
  opacity: 0;
  visibility: hidden;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.loading-logo {
  font-family: var(--font-primary);
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  color: var(--accent-color);
  text-shadow: 0 0 10px rgba(0, 194, 255, 0.5);
}

.loading-bar {
  width: 200px;
  height: 2px;
  background: var(--border-color);
  position: relative;
  overflow: hidden;
}

.loading-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: var(--accent-color);
  animation: loading 1.5s infinite;
  box-shadow: 0 0 10px var(--accent-color);
}

.loading-text {
  font-family: var(--font-mono);
  font-size: 0.9rem;
  color: var(--text-secondary);
  letter-spacing: 0.1rem;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}

/* Glitch Effect - Removing the effect */
.glitch-container {
  position: relative;
}

.glitch {
  position: relative;
}

.glitch::before,
.glitch::after {
  display: none;
}

@keyframes glitch-anim {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes glitch-anim2 {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

/* Static TV effect with RMW text */
.static-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--font-primary);
  font-size: 5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 0 5px rgba(0, 194, 255, 0.5);
  z-index: 2;
  letter-spacing: 0.5rem;
  animation: flicker 0.15s infinite;
}

.static-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.85' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  opacity: 0.1;
  mix-blend-mode: overlay;
  pointer-events: none;
  z-index: -1;
}

@keyframes flicker {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.95;
  }
  100% {
    opacity: 0.8;
  }
}

/* Header */
.site-header {
  padding: 30px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
}

.logo {
  font-family: var(--font-primary);
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  color: var(--accent-color);
  text-shadow: 0 0 10px rgba(0, 194, 255, 0.3);
}

.header-line {
  flex-grow: 1;
  height: 1px;
  background-color: var(--border-color);
  margin: 0 30px;
  position: relative;
  overflow: hidden;
}

.header-line::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--accent-color);
  opacity: 0.3;
  animation: pulse 4s infinite;
}

.header-text {
  font-family: var(--font-mono);
  font-size: 0.9rem;
  letter-spacing: 0.2rem;
  color: var(--text-secondary);
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 0 5%;
  position: relative;
  z-index: 10;
}

/* Hero Section */
.hero-section {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 50px 0;
}

/* Title Container - Enhanced with effects */
.title-container {
  position: relative;
  z-index: 10;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  position: relative;
}

.title-line {
  font-family: var(--font-primary);
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0.1rem;
  color: var(--text-color);
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

/* Typing effect */
.title-typed {
  position: relative;
  background: linear-gradient(to right, var(--text-color), var(--accent-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(0, 194, 255, 0.3);
}

.title-cursor {
  display: inline-block;
  width: 3px;
  height: 4.5rem;
  background-color: var(--accent-color);
  margin-left: 2px;
  vertical-align: middle;
  animation: cursor-blink 1s infinite;
}

@keyframes cursor-blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Title effects */
.title-effects {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.title-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 194, 255, 0.15) 0%,
    rgba(0, 194, 255, 0) 70%
  );
  filter: blur(10px);
  opacity: 0;
  animation: glow-pulse 4s ease-in-out infinite;
}

@keyframes glow-pulse {
  0%, 100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}

.title-scan-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--accent-color);
  box-shadow: 0 0 10px var(--accent-color), 0 0 20px var(--accent-color);
  opacity: 0.7;
  animation: scan-line 3s linear infinite;
}

@keyframes scan-line {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

.subtitle {
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--text-secondary);
  letter-spacing: 0.1rem;
  position: relative;
  opacity: 0;
  animation: fade-in 1s ease-out forwards 1.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-graphic {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 40%;
  height: 60%;
  background: var(--gradient-dark);
  border-left: 1px solid var(--border-color);
  opacity: 0.8;
  overflow: hidden;
}

.tech-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border: 1px solid var(--accent-color);
  border-radius: 50%;
  opacity: 0.5;
  animation: rotate 20s linear infinite;
}

.tech-circle::before,
.tech-circle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--accent-color);
  border-radius: 50%;
  opacity: 0.3;
}

.tech-circle::before {
  width: 150px;
  height: 150px;
  animation: rotate 15s linear infinite reverse;
}

.tech-circle::after {
  width: 250px;
  height: 250px;
  animation: rotate 25s linear infinite;
}

.tech-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(to right, var(--border-color) 1px, transparent 1px),
    linear-gradient(to bottom, var(--border-color) 1px, transparent 1px);
  background-size: 20px 20px;
  opacity: 0.2;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* About Section */
.about-section {
  padding: 100px 0;
  position: relative;
}

.section-content {
  max-width: 600px;
}

.about-section h2 {
  font-family: var(--font-primary);
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;
  color: var(--accent-color);
}

.about-section h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 2px;
  background-color: var(--accent-color);
  box-shadow: 0 0 10px var(--accent-color);
}

.about-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--text-secondary);
}

.tech-indicator {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.pulse {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: var(--accent-color);
  border-radius: 50%;
  box-shadow: 0 0 10px var(--accent-color);
  animation: pulse-animation 2s infinite;
}

.tech-label {
  font-family: var(--font-mono);
  font-size: 0.9rem;
  color: var(--accent-color);
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 194, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 194, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 194, 255, 0);
  }
}

/* Capabilities Section */
.capabilities-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  padding: 50px 0 100px;
}

.capability-item {
  padding: 30px;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.capability-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--gradient-accent);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}

.capability-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.capability-item:hover::before {
  transform: scaleX(1);
}

.capability-icon {
  width: 60px;
  height: 60px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.capability-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: var(--accent-color);
  opacity: 0.2;
  filter: blur(5px);
  border-radius: 50%;
}

.tech-icon-1::before,
.tech-icon-2::before,
.tech-icon-3::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.tech-icon-1::before {
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  animation: float 3s ease-in-out infinite;
}

.tech-icon-2::before {
  border: 1px solid var(--accent-color);
  transform: rotate(45deg);
  animation: pulse 3s ease-in-out infinite;
}

.tech-icon-3::before {
  border: 1px solid var(--accent-color);
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

.capability-item h3 {
  font-family: var(--font-primary);
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--accent-color);
}

.capability-item p {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Tech Showcase Section */
.tech-showcase {
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
}

.tech-visual {
  height: 300px;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
}

.data-stream {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, 
    transparent 0%, 
    var(--accent-color) 50%, 
    transparent 100%);
  background-size: 100% 20px;
  animation: data-flow 10s linear infinite;
  opacity: 0.1;
}

@keyframes data-flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 1000px;
  }
}

.tech-specs {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.spec-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.spec-label {
  font-family: var(--font-mono);
  font-size: 1rem;
  color: var(--text-color);
}

.spec-bar {
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
}

.spec-bar span {
  display: block;
  height: 100%;
  background: var(--gradient-accent);
  border-radius: 2px;
  animation: load 2s ease-in-out;
}

@keyframes load {
  0% {
    width: 0;
  }
}

/* Statement Section */
.statement-section {
  padding: 100px 0;
  text-align: center;
}

.statement-section blockquote {
  font-family: var(--font-primary);
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding: 0 40px;
  color: var(--text-color);
}

.statement-section blockquote::before,
.statement-section blockquote::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: var(--accent-color);
  box-shadow: 0 0 10px var(--accent-color);
}

.statement-section blockquote::before {
  top: 0;
  left: 0;
}

.statement-section blockquote::after {
  bottom: 0;
  right: 0;
}

/* Footer */
.site-footer {
  padding: 50px 5%;
  background-color: var(--secondary-color);
  border-top: 1px solid var(--border-color);
  position: relative;
  z-index: 10;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  position: relative;
}

.footer-logo {
  font-family: var(--font-primary);
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  color: var(--accent-color);
}

.contact a {
  color: var(--text-color);
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
  font-family: var(--font-mono);
}

.contact a:hover {
  color: var(--accent-color);
}

.footer-text {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-top: 20px;
}

/* Remove footer matrix */
.footer-matrix {
  display: none;
}

.matrix-line {
  display: none;
}

/* Responsive Design */
@media (max-width: 992px) {
  .title-line {
    font-size: 3.5rem;
  }
  
  .title-cursor {
    height: 3.5rem;
  }
  
  .hero-graphic {
    width: 30%;
  }
  
  .tech-showcase {
    grid-template-columns: 1fr;
  }
  
  .static-text {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .title-line {
    font-size: 3rem;
  }
  
  .title-cursor {
    height: 3rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
  
  .hero-graphic {
    display: none;
  }
  
  .about-section h2 {
    font-size: 2rem;
  }
  
  .statement-section blockquote {
    font-size: 2rem;
    padding: 0 20px;
  }
  
  .static-text {
    font-size: 3.5rem;
  }
}

@media (max-width: 576px) {
  .site-header {
    padding: 20px 5%;
    flex-wrap: wrap;
  }
  
  .logo {
    font-size: 1.5rem;
  }
  
  .header-text {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }
  
  .title-line {
    font-size: 2.5rem;
  }
  
  .title-cursor {
    height: 2.5rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .about-section p {
    font-size: 1rem;
  }
  
  .statement-section blockquote {
    font-size: 1.5rem;
  }
  
  .static-text {
    font-size: 3rem;
    letter-spacing: 0.3rem;
  }
}
